// IngredientAdmin.js
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './ShowRecipe.css'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import { getToken } from '../util/api'
import { getApiServerUrl } from '../Config'



const IngredientAdmin = () => {
  const [ingredients, setIngredients] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pageNo, setPageno] = useState(1)
  const [pageSize, setPagesize] = useState(25)
  const [totalelements, setTotalelements] = useState(0)

  const token = getToken()
  if (!token) {
    window.location = '/login'
  }

  useEffect(() => {
      if (searchKeyword !== null) {
        handleKeyPressOrPageChange(false)
      } else {
        fetchIngredients()
      }
    }, [pageNo, pageSize])

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value)
  }
  // Update the handleKeyPress function
  const handleKeyPressOrPageChange = (isPress) => {
      var pageNumNew = 0
      if(isPress == false){
          pageNumNew = pageNo - 1
      }
    if (searchKeyword !== '') {
      const apiUrl = getApiServerUrl()
      axios.get(`${apiUrl}/admin/ingredient/list`,
        {
          headers: {
            'Authorization': 'Bearer ' + token,
          },
          params: {
            pageNo: pageNumNew,
            pageSize,
            searchKeyword
          }
        })
        .then(response => {
          // Handle response
          const data = response.data

          setIngredients(data.items)
          setPageno(data.page + 1)
          setPagesize(data.pageSize)
          setTotalelements(data.counts)
        })
        .catch(error => {
          // Handle error
          console.error(error)
        })
    } else {
      fetchIngredients()
    }

  }

  const fetchIngredients = async () => {
    const apiUrl = getApiServerUrl()

    try {
      axios.get(`${apiUrl}/admin/ingredient`
        ,
        {
          headers: {
            'Authorization': 'Bearer ' + token,
          },
          params: {
            pageNo: pageNo - 1,
            pageSize,
            searchKeyword
          }
        })
        .then(response => {
          setIngredients(response.data.items)
        })

    } catch (error) {
      console.error('Error fetching ingredients:', error)
    }
  }

  const handleRemoveIngredient = async (id) => {
    const apiUrl = getApiServerUrl()
    const confirmDelete = window.confirm('Are you certain about deleting this?')
    if (confirmDelete) {
      try {
        await axios.delete(`${apiUrl}/admin/ingredient/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
        // Fetch ingredients again to update the list after removing an ingredient
        fetchIngredients()
      } catch (error) {
        console.error('Error removing ingredient:', error)
      }
    }
  }

  return (
    <div>
      <h4>Ingredient Administration</h4>
      {/* Display the list of ingredients */}
      <div className="container">
        <div className="row mb-3 searchheader">
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="bi bi-search" onClick={() => handleKeyPressOrPageChange(true)} ></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search ingredients..."
                value={searchKeyword}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-auto ml-auto">
            <Link to="/ingredient-input-form">
              <button className="btn btn-primary">New Ingredient</button>
            </Link>
          </div>
        </div>
        <div className="ingredients-body">
          <Table striped>
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>Group</th>
                <th>Subgroup</th>
                <th>English</th>
                <th>Norwegian</th>
                <th>Lithuanian</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {ingredients.length > 0 ? (ingredients.map((ingredient) => (
                <tr key={ingredient.id} >
                  <td style={{ marginLeft: "20px " }}>{ingredient.id}</td>
                  <td>{ingredient.image}</td>
                  <td>{ingredient.group}</td>
                  <td>{ingredient.subgroup}</td>
                  <td>{ingredient.title.en}</td>
                  <td>{ingredient.title.no}</td>
                  <td>{ingredient.title.lt}</td>
                  <td>
                    <div className="button-container">
                      <Link to={`/ingredient/${ingredient.id}`}>
                        <Button variant="primary" className="me-2 editbn">Edit</Button>

                      </Link>
                      <span>|</span>
                      <Button variant="primary" className='deletebn' onClick={() => handleRemoveIngredient(ingredient.id)}>Remove</Button>

                    </div>
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center" style={{ color: '#919399', fontSize: '16px' }}>
                    No data yet
                  </td>
                </tr>

              )}
            </tbody>
          </Table>
        </div>
        <div className='footer'>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={pageNo === 1} />
            <Pagination.Prev onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1} />
            <Pagination.Item>{pageNo}</Pagination.Item>
            <Pagination.Next onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo >= (totalelements / pageSize)} />
            <Pagination.Last onClick={() => handlePageChange(totalelements / pageSize)} disabled={pageNo >= (totalelements / pageSize)} />
          </Pagination>
        </div>
      </div >
    </div >
  )
}

export default IngredientAdmin
