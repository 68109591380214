import axios from 'axios'

import { findRandomRecipe, findRecipe } from './recipe.js'
import { getAllFood } from './ingredients.js'

import { getApiServerUrl } from '../Config'

function setToken(token, expires) {
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('expires', expires)
}

function getToken() {
    const expires = window.localStorage.getItem('expires')
    if (expires) {
        if (expires > Date.now()) {
            return window.localStorage.getItem('token')
        } else {
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('expires')
        }
    }
    return null
}

function getIngredients() {
    const lastIngredientsUpdate = window.localStorage.getItem('ingredients_last_update')
    const currentTimestamp = Math.floor(new Date().getTime() / 1000)

    // a bit for optimization to avoid every single call for ingredients,
    // we check for new ingredients only if there was more than 5 minutes from last update
    if (currentTimestamp - lastIngredientsUpdate > 1 * 60 * 5) {
        const token = getToken()
        const apiUrl = getApiServerUrl()

        const response = axios.get(`${apiUrl}/admin/ingredient/autocomplete`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            params: {
                lastUpdated: lastIngredientsUpdate
            },
        })

        response.then(ingredientsList => {
            if (ingredientsList.status === 200) {
                window.localStorage.setItem('ingredients', JSON.stringify(ingredientsList.data))
            }
            window.localStorage.setItem('ingredients_last_update', currentTimestamp)
        })
    }

    const ingredientsString = window.localStorage.getItem('ingredients')
    if (ingredientsString) {
        const ingredients = JSON.parse(ingredientsString)

        if (ingredients && ingredients.length) {
            return ingredients
        }
    }
    return []
}

export { findRandomRecipe, findRecipe, getAllFood, getIngredients, setToken, getToken }
