// IngredientForm.js
import React, { useCallback, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

import "./AddIngredientForm.css"
import { Link } from 'react-router-dom'
import axios from 'axios'
import { getApiServerUrl } from '../Config'
import { getToken } from '../util/api'
import { useNavigate } from 'react-router'

const IngredientForm = ({ ingredientData }) => {
  const [id, setId] = useState('')
  const [enTranslation, setEnTranslation] = useState('')
  const [noTranslation, setNoTranslation] = useState('')
  const [ltTranslation, setLtTranslation] = useState('')
  const [image, setImage] = useState('')
  const [icon, setIcon] = useState('')
  const [group, setGroup] = useState('')
  const [subgroup, setSubgroup] = useState('')

  const navigate = useNavigate()

  const token = getToken()
  if (!token) {
    window.location = '/login'
  }

  useEffect(() => {
    if (ingredientData) {
        setId(ingredientData.id)
        setImage(ingredientData.image)
        setIcon(ingredientData.icon)
        setGroup(ingredientData.group)
        setSubgroup(ingredientData.subgroup)
        setEnTranslation(ingredientData.title.en)
        setNoTranslation(ingredientData.title.no)
        setLtTranslation(ingredientData.title.lt)
    }
  }, [ingredientData])

  const handleAddIngredient = async (newIngredient) => {
    const token = getToken()
    const apiUrl = getApiServerUrl()
    try {
      const response = await axios.post(`${apiUrl}/admin/ingredient`, newIngredient, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
    } catch (error) {
      console.error('Error adding ingredient:', error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const newIngredient = {
      id: id,
      image: image,
      icon: icon,
      group: group,
      subgroup: subgroup,
      title: {
        en: enTranslation,
        no: noTranslation,
        lt: ltTranslation,
      },
    }
    handleAddIngredient(newIngredient)
    // Clear the form fields after submitting
    setId('')
    setEnTranslation('')
    setNoTranslation('')
    setLtTranslation('')
    setImage('')
    setIcon('')
    setGroup('')
    setSubgroup('')

    navigate('/ingredient')
  }

  const handleDrop = useCallback((e) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          const reader = new FileReader();
          reader.onloadend = function() {
            setIcon(reader.result)
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault(); // Prevent default behavior (Prevent file from being opened)
  }, []);

  return (
    <div className="add-ingredient-form">
      <h2>Add New Ingredient</h2>
      <div>
        <div className="labels-row">
          <label>
            ID:
            <input type="text" value={id} onChange={(e) => setId(e.target.value)} />
          </label>
          <label>
            EN Title:
            <input type="text" value={enTranslation} onChange={(e) => setEnTranslation(e.target.value)} />
          </label>
        </div>
        <div className="labels-row">
          <label>
            NO Title:
            <input type="text" value={noTranslation} onChange={(e) => setNoTranslation(e.target.value)} />
          </label>
          <label>
            LT Title:
            <input type="text" value={ltTranslation} onChange={(e) => setLtTranslation(e.target.value)} />
          </label>
        </div>
{/*         <div className="labels-row">
          <label>
            Group:
            <select value={group} onChange={(e) => setGroup(e.target.value)}>
              <option value="group1">Group 1</option>
              <option value="group2">Group 2</option>
            </select>
          </label>
          <label>
            Subgroup:
            <select value={subgroup} onChange={(e) => setSubgroup(e.target.value)}>
              <option value="subgroup1">Subgroup 1</option>
              <option value="subgroup2">Subgroup 2</option>
            </select>
          </label>
        </div> */}
        <div className="image-row">
          <label>
            Image:
            <input type="text" value={image} onChange={(e) => setImage(e.target.value)} />
          </label>
        </div>
        <div className="image-row">
            Icon:
          
            <div 
              onDrop={handleDrop} 
              onDragOver={handleDragOver} 
              style={{ border: '2px dashed #ccc', padding: 30, width: '100%', margin: '0px', textAlign: 'center' }}
            >
              Drag icon here
              {icon && 
                <div>
                  <img src={icon} />
                </div>
              }
            </div>
         
        </div>
        <div className="button-container">

          <Button variant="primary" onClick={handleSubmit}>Add</Button>
          <Link to="/ingredient">
            <Button variant="primary" style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#DC3545' }}>Cancel</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}


export default IngredientForm
