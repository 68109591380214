const LOCAL_API_URL = 'http://localhost:8080'
const SERVER_API_URL = 'https://api.foodtips.me'
const languageList = ['en', 'no', 'lt']

export function getApiServerUrl () {
  if (process.env.NODE_ENV === 'production') {
    return SERVER_API_URL
  } else {
    return LOCAL_API_URL
  }
}

export function getLoginRedirectUrl () {
  if (process.env.NODE_ENV === 'production') {
    return "https://app-admin.foodtips.me/login-result"
  } else {
    return "http://localhost:3000/login-result"
  }
}

export { languageList }