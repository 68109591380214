import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import {useState, useEffect} from "react";
import { Routes, Route, Link } from 'react-router-dom'
import './util/i18n'
import RecipeInputForm from './screen/RecipeInputForm'
import RecipeList from './screen/ShowRecipe'
import EditRecipe from './screen/EditRecipe'
import LoginRedirect from './LoginRedirect.js'
import LoginResult from './LoginResult.js'
import EditIngredient from './screen/EditIngredient'
import logo from './images/logo.png'
import IngredientAdmin from './screen/IngredientAdmin'
import IngredientForm from './screen/IngredientForm'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import { BsBoxArrowRight, BsBook, BsPerson } from 'react-icons/bs'
import { FaUtensils } from 'react-icons/fa'

const logout = () => {
  localStorage.removeItem("token");
}

function App () {
  const [expires, setExpires] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenExpires = localStorage.getItem('expires')
      if (tokenExpires) {
        const date = new Date(parseInt(tokenExpires, 10))
        setExpires('expires in ' + date.toLocaleTimeString())
      } else {
        setExpires(null)
      }
    }, 5000);

    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        border: '0px solid red',
        width: '96%',
        height: '100vh',
        marginLeft: '2%',
        marginRight: '2%',
      }}
    >
      <div className="main-block">
        <Container fluid>
          <Row>
            <Col xs={2} id="sidebar-wrapper">
              <Navbar expand="lg" variant="white" className="flex-column">
                <Navbar.Toggle aria-controls="sidebar-nav" />
                <Navbar.Collapse id="sidebar-nav">
                  <Nav className="flex-column">
                    <Nav.Link >
                      <img src={logo} alt="Logo" style={{ width: '150px', height: '100px', marginTop: '20px' }} />
                    </Nav.Link>
                    <Nav.Link href='/recipe-list' style={{ marginTop: '40px' }} className="sidebar-link" >
                        <BsBook className="me-2" /> Recipe
                    </Nav.Link>
                    <Nav.Link href='/ingredient' className="sidebar-link">
                        <FaUtensils className="me-2" />Ingredient
                    </Nav.Link>
                    <Nav.Link href="" className="sidebar-link" >
                      <BsPerson className="me-2" />User
                    </Nav.Link>
                    <Nav.Link href=""></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
            <Col xs={10} id="page-content-wrapper" style={{ marginTop: "20px" }}>
              <Navbar expand="lg" variant="light" >
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                </Navbar.Collapse>
                <Nav>
                  <Nav.Link href="#logout" onClick={logout}>
                    <BsBoxArrowRight className="mr-1" /> Logout ({expires})
                  </Nav.Link>
                </Nav>
              </Navbar>
              <Routes>
                <Route path="/" element={< RecipeList />} />
                <Route path="/home" element={< RecipeList />} />
                <Route path="/ingredient-input-form" element={<IngredientForm />} />
                <Route path="/recipe-input-form" element={<RecipeInputForm />} />
                <Route path="/recipe-list" element={< RecipeList />} />
                <Route path="/recipe/:id" element={<EditRecipe />} />
                <Route path="/ingredient/:id" element={<EditIngredient />} />
                <Route path="/ingredient" element={<IngredientAdmin />} />
                <Route path="/login" element={<LoginRedirect />} />
                <Route path="/login-result" element={<LoginResult />} />
              </Routes>
            </Col>
          </Row>
        </Container >
      </div>

    </div>
  )
}

export default App
