export default {
    translation: {
        'app.scree.recipe-alternatives': 'Oppskriftsalternativer',
        'app.screen.shopping-list': 'Handleliste',
        'app.screen.foot-at-home': 'Mat hjemme',
        'app.screen.profile': 'Profil',
        'app.screen.profile.food-preferences': 'Matpreferanser',
        'app.screen.profile.language': 'Språk',
        'app.screen.profile.share-app': 'Dele-app',
        'appmenu.home': 'Hjem',
        'appmenu.food-at-home': 'Mat hjemme',
        'appmenu.shopping-list': 'Handleliste',
        'appmenu.profile': 'Profil',

        'What are we eating today?': 'Hva spiser vi i dag?',
        'Make menu for a week': 'Lag meny for en uke',
        'Recipes for inspiration': 'Oppskrifter for inspirasjon',

        //irene
        'Copy and share the link below:': 'Kopier og del lenken nedenfor:',
        'empty shopping list': 'Handlelisten din er tom.',
        'Do not ask if home food is empty': 'Ikke spør om hjemmets mat er tom',
        'Select': 'Velg',
        'Close': 'Lukk',
        'Enter an item': 'Skriv inn en gjenstand',
        'items': 'gjenstander',
        'Check all': 'Merk alle',
        'Uncheck all': 'fjern merking på alle',
        'Delete checked': 'slett merkede',
        'ingredients you have at home': 'ingredienser du har hjemme',
        'Edit': 'Rediger',
        'Save Changes': 'Lagre Endringer',
        'Cancel': 'Avbryt',
        'Select Language': 'Velg Språk"',
        'breakfast': 'frokost',
        'lunch': 'lunsj',
        'dinner': 'middag',
    }
}