import Form from 'react-bootstrap/Form'

import './MultiLanguageInput.css'

const MultiLanguageInput = ( {label, values, onChange} ) => {
    return <div className='MultiLanguageInput'>
        <label>{label}</label>
        <div className='MultiLanguageInput-language-input'>
            <label>LT:</label> 
            <Form.Control type="text" placeholder="" value={values.lt} onChange={(e) => onChange('lt', e.target.value) } />
        </div>
        <div className='MultiLanguageInput-language-input'>
            <label>EN:</label> 
            <Form.Control type="text" placeholder="" value={values.en} onChange={(e) => onChange('en', e.target.value) } />
        </div>
        <div className='MultiLanguageInput-language-input'>
            <label>NO:</label> 
            <Form.Control type="text" placeholder="" value={values.no} onChange={(e) => onChange('no', e.target.value) } />
        </div>
    </div>
}

export default MultiLanguageInput