import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './ShowRecipe.css'
import { Table, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from 'react-bootstrap/Pagination'
import { getApiServerUrl } from '../Config'
import { getToken } from '../util/api'

const RecipeList = () => {
  const [recipes, setRecipes] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pageNo, setPageno] = useState(1)
  const [pageSize, setPagesize] = useState(25)
  const [totalelements, setTotalelements] = useState(0)
  const [unpublished, setUnpublished] = useState(false)

  const token = getToken()
  if (!token) {
    window.location = '/login'
  }

  useEffect(() => {
    fetchRecipes()
  }, [pageNo, pageSize])

  const fetchRecipes = async () => {
    const token = getToken()
    try {
      const apiUrl = getApiServerUrl()
      const response = await axios.get(`${apiUrl}/admin/recipe`, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        params: {
          pageNo: pageNo - 1, 
          pageSize
        }
      })
      const data = response.data
      setRecipes(data.items)
      setPageno(data.page + 1)
      setPagesize(data.pageSize)
      setTotalelements(data.counts)
    } catch (error) {
      console.error('Error fetching recipes:', error)
    }
  }

  const handlePageChange = (newPageNo) => {
    setPageno(newPageNo)
  }

  const handleUnpublishedChange = (e) => {
    const isChecked = e.target.checked
    setUnpublished(isChecked)
  }

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value)
  }

  // Update the handleKeyPressOrPageChange function
  const handleKeyPressOrPageChange = (isPress) => {
    var pageNumNew = 0
    if(isPress == false){
        pageNumNew = pageNo - 1
    }
    if (searchKeyword !== '') {
      const apiUrl = getApiServerUrl()
      axios.get(`${apiUrl}/admin/recipe/list`,
        {
          headers: {
            'Authorization': 'Bearer ' + token,
          },
          params: {
            pageNo: pageNumNew,
            pageSize,
            searchKeyword
          }
        })
        .then(response => {
          // Handle response
          const data = response.data

          setRecipes(data.items)
          setPageno(data.page + 1)
          setPagesize(data.pageSize)
          // setTotalelements(data.counts)
          setTotalelements(data.counts)
        })
        .catch(error => {
          // Handle error
          console.error(error)
        })
    } else {
      fetchRecipes()
    }
  }

  const handleUnpublished = () => {
    const token = getToken()

    if (unpublished) {
      // Send GET request with published: true parameter
      const apiUrl = getApiServerUrl()
      axios.get(`${apiUrl}/admin/recipe/unpublished`, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        params: {
          published: false,
          pageNo: pageNo - 1,
          pageSize
        }
      })
        .then(response => {
          // Handle response
          const data = response.data

          setRecipes(data.items)
          setPageno(data.page + 1)
          setPagesize(data.pageSize)
          setTotalelements(data.counts)
        })
        .catch(error => {
          // Handle error
          console.error(error)
        })
    } else {
      fetchRecipes()
    }
  }

  const handleEdit = (recipe) => {
    // const { id, title, images, typesOfMeals, authorName, authorUrl, authorLogo, servings, timeAmount, timeUnit, published } = recipe
    const recipeData = {
      recipe: {
        id: recipe.id,
        title: recipe.title,
        images: recipe.images,
        typesOfMeals: recipe.typesOfMeals,
        authorName: recipe.author.name,
        authorUrl: recipe.author.url,
        authorLogo: recipe.author.logo,
        servings: recipe.servings,
        timeAmount: recipe.time.amount,
        timeUnit: recipe.time.unit,
        published: recipe.published
      },
      // ingredients: recipe.ingredients,
      ingredients: recipe.ingredients.map(ingredient => {
        return {
          amount: ingredient.amount,
          unit: ingredient.unit,
          itemId: ingredient.item.id,
          itemType: ingredient.item.type,
          itemTitle: ingredient.item.title,
        }
      }),
      cookingProcess: recipe.cookingProcess
    }

    localStorage.setItem('editRecipe', JSON.stringify(recipeData))
  }

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you certain about deleting this?')
    if (confirmDelete) {
      const deleteId = parseInt(id, 10)
      const apiUrl = getApiServerUrl()
      axios.delete(`${apiUrl}/admin/recipe/${deleteId}`, {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => {
          alert("delete successfully")
          window.location.reload() // Refresh the page
        })
        .catch(error => {

        })
    }
  }

  const recipeTitleView = (title) => {
    return <div>
      <div>EN: {title.en}</div>
      <div>NO: {title.no}</div>
      <div>LT: {title.lt}</div>
    </div>
  }

  return (
    <div>
      <h4>Recipe Administration</h4>
      <div className="container">
        <div className="row mb-3 searchheader">
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="bi bi-search" onClick={() => handleKeyPressOrPageChange(true)} ></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search recipes..."
                value={searchKeyword}
                onChange={handleSearchChange}
                onKeyDown={(e) => { if (e.code === 'Enter') { handleKeyPressOrPageChange(true) } }}
              />
            </div>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              value="true"
              checked={unpublished}
              onChange={handleUnpublishedChange}
              onClick={handleUnpublished}
            />
            <span style={{ marginLeft: '5px' }}>unpublished</span>
          </div>

          <div className="col-auto ml-auto">
            <Link to="/recipe-input-form">
              <button className="btn btn-primary">New Recipe</button>
            </Link>
          </div>
        </div>
        <div className="recipe-body">
          <Table striped>
            <thead>
              <tr>
                <th></th>
                <th>Recipe Title</th>
                <th>Meal Type</th>
                <th>Cook Time</th>
                <th>Author Name</th>
                <th>Publish Status</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {recipes.length > 0 ? (recipes.map((recipe) => (
                <tr key={recipe.id}>
                  <td>{recipe.id}</td>
                  <td>{recipeTitleView(recipe.title)}</td>
                  <td>{recipe.typesOfMeals.join(", ")}</td>
                  <td>{`${recipe.time.amount}${recipe.time.unit}`}</td>
                  <td>{recipe.author.name}</td>
                  <td>{recipe.published ? "Published" : "Unpublished"}</td>
                  <td>
                    <div className="button-container">
                      <Link to={`/recipe/${recipe.id}`}>
                        <Button variant="primary" className="me-2 editbn" onClick={() => handleEdit(recipe)}>Edit</Button>
                      </Link>
                      <span>|</span>
                      <Button variant="primary" className='deletebn' onClick={() => handleDelete(recipe.id)}>Delete</Button>
                    </div>
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center" style={{ color: '#919399', fontSize: '16px' }}>
                    No data yet
                  </td>
                </tr>

              )}
            </tbody>
          </Table>
        </div>
        <div className='footer'>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={pageNo === 1} />
            <Pagination.Prev onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1} />
            <Pagination.Item>{pageNo}</Pagination.Item>
            <Pagination.Next onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo >= (totalelements / pageSize)} />
            <Pagination.Last onClick={() => handlePageChange(totalelements / pageSize)} disabled={pageNo >= (totalelements / pageSize)} />
          </Pagination>
        </div>
      </div >
    </div>
  )
}

export default RecipeList
