import { getLoginRedirectUrl } from './Config';

export default function LoginRedirect() {
    const redirectUrl = getLoginRedirectUrl();

    const authUrl = "https://api.foodtips.me/auth/login"
 
    window.location = authUrl;

    return <div>
        
    </div>
}
