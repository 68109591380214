export default {
    translation: {
        'app.scree.recipe-alternatives': 'Alternatives',
        'app.screen.shopping-list': 'Shopping list',
        'app.screen.foot-at-home': 'Food at home',
        'app.screen.profile': 'Profile',
        'app.screen.profile.food-preferences': 'Food Preferences',
        'app.screen.profile.language': 'Language',
        'app.screen.profile.share-app': 'Share App',
        'appmenu.home': 'Home',
        'appmenu.food-at-home': 'Food at home',
        'appmenu.shopping-list': 'Shopping list',
        'appmenu.profile': 'Profile',

        'Copy and share the link below:': 'Copy and share the link below:',
        'What are we eating today?': 'What are we eating today?',
        'Make menu for a week': 'Make menu for a week',
        'Recipes for inspiration': 'Recipes for inspiration',
        'Have at home': 'Have at home',

        //irene
        'Enter an item': 'Enter an item',
        'items': 'items',
        'Check all': 'Check all',
        'Uncheck all': 'Uncheck all',
        'Delete checked': 'Delete checked',
        'empty shopping list': 'Your shopping list seems to be empty.',
        'Do not ask if home food is empty': 'Do not ask if home food is empty',
        'Select': 'Select',
        'ingredients you have at home': 'ingredients you have at home',
        'Edit': 'Edit',
        'Save Changes': 'Save Changes',
        'Cancel': 'ancel',
        'Select Language': 'Select Language',
    }
}