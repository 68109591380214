import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'



const CustomAutocomplete = ({ ingredientList, value, onChange }) => {
  const handleIngredientChange = (event, newValue) => {
    // Check if a valid ingredient is selected
    if (newValue) {
      // Pass the selected ingredient to the parent component
      // check if it's object (it will be object if selected from list)
      // if not object but, string (did not selected from list, maybe new ingredient) - create object
      if (typeof newValue === 'string' || newValue instanceof String) {
        newValue = { id: newValue, title: {}}
      }

      onChange(newValue)
    }
  }

  if (value == null) {
    value = ''
  }

  return (
    <Autocomplete
      freeSolo={true}
      options={ingredientList}
      getOptionLabel={(ingredient) => {
        return ingredient.id || ingredient} 
      }
      isOptionEqualToValue={(option, value) => { 
        return option.id === value
      }}
      noOptionsText="No available ingredient"
      renderOption={(props, ingredient) => (
        <Box component="li" {...props} key={ingredient.id}>
          {ingredient.id}
        </Box>
      )}
      value={value}
      //inputValue={value}
      onChange={handleIngredientChange}
      renderInput={(params) => <TextField {...params}></TextField>}
    />
  )
}

export default CustomAutocomplete
