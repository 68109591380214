
import './RecipeAuthor.css'
import TextInput from './TextInput'

const RecipeAuthor = ( {recipe, onChange} ) => {
    return <div className='RecipeAuthor'>
        <label>Author</label>
        <TextInput label="name" value={recipe.authorName} onChange={value => onChange('authorName', value)} />
        <TextInput label="url" value={recipe.authorUrl} onChange={value => onChange('authorUrl', value)} />
        <TextInput label="logo" value={recipe.authorLogo} onChange={value => onChange('authorLogo', value)} />
    </div>
}

export default RecipeAuthor