import Form from 'react-bootstrap/Form'

import './MultiLanguageText.css'

const MultiLanguageText = ( {label, values, onChange} ) => {
    return <div className='MultiLanguageInput'>
        <label>{label}</label>
        <div className='MultiLanguageInput-language-input'>
            <label>LT:</label> 
            <Form.Control as="textarea" rows={3} value={values.lt} onChange={(e) => { console.log(e); onChange('lt', e.target.value) } }/>
        </div>
        <div className='MultiLanguageInput-language-input'>
            <label>EN:</label> 
            <Form.Control as="textarea" rows={3} value={values.en} onChange={(e) => onChange('en', e.target.value) } />
        </div>
        <div className='MultiLanguageInput-language-input'>
            <label>NO:</label> 
            <Form.Control as="textarea" rows={3} value={values.no} onChange={(e) => onChange('no', e.target.value) } />
        </div>
    </div>
}

export default MultiLanguageText