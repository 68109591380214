import CustomAutocomplete from './CustomAutocomplete'
import MultiLanguageInput from './MultiLanguageInput'
import SingleSelect from './SingleSelect'
import TextInput from './TextInput'

import './IngredientItem.css'

import { BsPlus, BsTrash } from 'react-icons/bs'

const IngredientItem = ({ingredientIndex, ingredient, ingredientList, updateIngredient, addOneIngredient, removeOneIngredient}) => {
    const handleIngredientChange = (ingredient) => {
		updateIngredient('itemTitle', ingredient.title)
		updateIngredient('itemId', ingredient.id)
	}

    const updateIngredientTitle = (lang, value) => {
        ingredient.itemTitle[lang] = value
        updateIngredient(ingredient)
    }

    return <div className="IngredientItem">
        <div className="IngredientItem--content">
				<div>

					{ingredient &&
                        <div className="IngredientItem--content--id">
                            <label>Ingredient ID:</label>
                            <CustomAutocomplete ingredientList={ingredientList}
                                key={ingredient.itemId}
                                value={ingredient.itemId}
                                onChange={handleIngredientChange}

                            />
                        </div>
					}

                    <SingleSelect 
                        label="Type"
                        options={['', 'main']}
                        value={ingredient.itemType ?? ''}
                        setValue={(value) => updateIngredient('itemType', value)}
                    />

                    <div className="recipe-input-ingredient-amount-unit">
						<TextInput label="Amount" value={ingredient.amount ?? ''} onChange={value => updateIngredient('amount', value)} />
						<TextInput label="Unit" value={ingredient.unit ?? ''} onChange={value => updateIngredient('unit', value)} />
					</div>

                    <MultiLanguageInput label="Title" values={ingredient.itemTitle} onChange={updateIngredientTitle} />
				</div>
			</div>
			<div className="ingre_add_delete gap-3">
				<button className="btn btn-primary" onClick={() => addOneIngredient(ingredientIndex)} >
					<BsPlus />
				</button>
				<button className="btn btn-danger" onClick={() => removeOneIngredient(ingredientIndex)} >
					<BsTrash />
				</button>
			</div>
    </div>
}

export default IngredientItem