import Switch from 'react-switch'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { useState, useEffect } from "react"

import { Link } from 'react-router-dom'
import { BsPlus, BsTrash } from 'react-icons/bs'
import { getApiServerUrl } from '../Config'
import { getIngredients, getToken } from '../util/api'

import IngredientItem from '../component/IngredientItem'
import MultiLanguageInput from '../component/MultiLanguageInput'
import MultiLanguageText from '../component/MultiLanguageText'
import RecipeAuthor from '../component/RecipeAuthor'
import RecipeTags from '../component/RecipeTags'
import SingleSelect from '../component/SingleSelect'
import TextInput from '../component/TextInput'

import './RecipeInputForm.css'

const token = getToken()
if (!token) {
	window.location = '/login'
}

const IngredientForm = ({ ingredient, ingredientList, idx, updateIngredient, addOneIngredient, removeOneIngredient }) => {
	return <IngredientItem 
		ingredientIndex={idx}
		ingredient={ingredient}
		ingredientList={ingredientList}
		updateIngredient={updateIngredient}
		addOneIngredient={addOneIngredient}
		removeOneIngredient={removeOneIngredient}
	/>
}

const IngredientsInputForm = ({ ingredients, ingredientList, addOneIngredient, updateIngredient, removeOneIngredient }) => {
	return <div className='Ingredients'>
		<label>Ingredients:</label>
		<div>
			{ingredients.map((ingredient, idx) => {
				return <IngredientForm ingredient={ingredient} ingredientList={ingredientList} key={idx} idx={idx} updateIngredient={(field, value) => updateIngredient(idx, field, value)} addOneIngredient={addOneIngredient} removeOneIngredient={removeOneIngredient} />
			})}
		</div>
	</div>
}

const CookingProcessInputForm = ({ steps, addOneCookingProcessStep, updateCookingProcessStep, removeOneCookingProcessStep }) => {
	return (
		<div>
			<div style={{ marginLeft: 140 }}>Cooking Process:</div>

			<div className="recipe-input-form-cooking-steps">
				{steps.map((step, index) =>
				(
					<div key={index}>
						<MultiLanguageInput label="" values={step} onChange={(lang, value) => updateCookingProcessStep(index, lang, value)} />

	
						<div className="cook_add_delete gap-3">
							<button className="btn btn-primary" onClick={() => addOneCookingProcessStep(index)}>
								<BsPlus />
							</button>
							<button className="btn btn-danger" onClick={() => removeOneCookingProcessStep(index)}>
								<BsTrash />
							</button>
						</div>
					</div>
				)
				)}
			</div>
		</div>
	)
}

export default function RecipeInputForm ({ recipeData, onSave }) {
	const idNumber = new Date().getTime() - new Date(2023, 6, 1).getTime()
	const [recipe, setRecipe] = useState({
		id: idNumber,
		title: {'en': '', 'no': '', 'lt': ''},
		images: '',
		typesOfMeals: 'dinner',
		authorName: 'Asta',
		authorUrl: 'https://kitchenfantasies.com/',
		authorLogo: 'https://kitchenfantasies.com/wp-content/uploads/2023/07/logo.png',
		servings: 2,
		timeAmount: 30,
		timeUnit: 'min',
		published: 'false',
		tags: [],
		difficultyLevel: "",
		introText: { en: '', lt: '', no: ''},
		footerText: { en: '', lt: '', no: ''},
	})

	const [ingredients, setIngredients] = useState([{
		// amount: "",
		// unit: "",
		// itemId: "",
		// itemType: "",
		itemTitle: {
			lt: "",
			en: "",
			no: "",
		}
	}])
	const [steps, setSteps] = useState([{ en: '', lt: '', no: '' }])

	const [tags, setTags] = useState([]);

	const [difficultyLevel, setDifficultyLevel] = useState("");

	const ingredientList = getIngredients()

	useEffect(() => {
		if (recipeData) {
			setRecipe({
				id: recipeData.id,
				title: recipeData.title,
				images: recipeData.images,
				typesOfMeals: recipeData.typesOfMeals,
				authorName: recipeData.author.name,
				authorUrl: recipeData.author.url,
				authorLogo: recipeData.author.logo,
				servings: recipeData.servings,
				timeAmount: recipeData.time.amount,
				timeUnit: recipeData.time.unit,
				published: recipeData.published,
				tags: recipeData.tags,
				difficultyLevel: recipeData.difficultyLevel,
				introText: recipeData.introText ? recipeData.introText : { en: '', lt: '', no: '' },
				footerText: recipeData.footerText ? recipeData.footerText : { en: '', lt: '', no: '' },
			})

			setIngredients(recipeData.ingredients.map(ingredient => {
				return {
					amount: ingredient.amount,
					unit: ingredient.unit,
					itemId: ingredient.item.id,
					itemType: ingredient.item.type,
					itemTitle: ingredient.item.title
				}
			})),

			// setIngredients(recipeData.ingredients)
			setSteps(recipeData.cookingProcess)

			if (recipeData.tags) {
				setTags(recipeData.tags)
			}

			if (recipeData.difficultyLevel) {
			    setDifficultyLevel(recipeData.difficultyLevel)
			}
		}
	}, [recipeData])

	const removeOneIngredient = (idx) => {
		setIngredients(arr => {
			const updatedArr = [...arr]
			const filterArr = updatedArr.filter((_, i) => i !== idx)
			return filterArr
		})
	}

	const helperCleanJson = (jsonData) => {
		var newJson = {}
		Object.keys(jsonData).forEach((key) => {
			const value = jsonData[key]
			if (key === 'itemTitle') {
				newJson['itemTitle'] = { lt: '', en: '', no: ''}
			} else {
				newJson[key] = ""
			}
		})
		return newJson
	}

	const addOneIngredient = (idx) => {
		setIngredients(arr => {
			const updateArr = [...arr]
			if (updateArr.length == 0) {
				updateArr.splice(idx, 0, {itemTitle: { lt: '', en: '', no: ''}})
			} else {
				updateArr.splice(idx + 1, 0, helperCleanJson(updateArr[0]))
			}
			return updateArr
		})
	}

	const updateIngredient = (idx, field, value) => {
		const copy = ingredients
		copy[idx][field] = value
		setIngredients([...copy])
	}

	const removeOneCookingProcessStep = (idx) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps]
			return updatedSteps.filter((_, i) => i !== idx)
		})
	}

	const addOneCookingProcessStep = (idx) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps]
			updatedSteps.splice(idx + 1, 0, { en: "", no: "", lt: "" })
			return updatedSteps
		})
	}

	const updateCookingProcessStep = (idx, languageList, value) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps]
			updatedSteps[idx][languageList] = value
			return updatedSteps
		})
	}

	const updateRecipeLanguageField = (field, lang, value) => {
		setRecipe(prevRecipe => ({
			...prevRecipe,
			[field]: {
				...prevRecipe[field],
				[lang]: value
			}
		}));
	}

	const updateRecipe = (field, value) => {
		const copy = recipe
		copy[field] = value
		setRecipe({ ...copy })
	}

	const updateRecipeTitle = (language, value) => {
		setRecipe(prevRecipe => ({
			...prevRecipe,
			title: {
				...prevRecipe.title,
				[language]: value
			}
		}));
	}

	const updateRecipeIntro = (language, value) => {
		updateRecipeLanguageField('introText', language, value)
	}

	const updateRecipeFooter = (language, value) => {
		updateRecipeLanguageField('footerText', language, value)
	}

	const showRecipeJson = (recipe, ingredients, steps) => {

		const json = {
			id: recipe.id,
			title: recipe.title,
			// images: recipe.images.split(','),
			// typesOfMeals: recipe.typesOfMeals.split(','),
			images: typeof recipe.images === 'string' ? recipe.images.split(',') : recipe.images,
			typesOfMeals: typeof recipe.typesOfMeals === 'string' ? recipe.typesOfMeals.split(',') : recipe.typesOfMeals,
			author: {
				name: recipe.authorName,
				url: recipe.authorUrl,
				logo: recipe.authorLogo
			},
			servings: recipe.servings,
			time: { amount: recipe.timeAmount, unit: recipe.timeUnit },
			published: recipe.published,
			ingredients: ingredients.map(ingredient => {
				return {
					amount: ingredient.amount,
					unit: ingredient.unit,
					item: {
						id: ingredient.itemId,
						type: ingredient.itemType,
						title: ingredient.itemTitle,
					}
				}
			}),
			introText: recipe.introText,
			cookingProcess: steps.map(step => {
				return {
					en: step.en,
					lt: step.lt,
					no: step.no
				}
			}),
			footerText: {
				en: recipe.footerText.en,
				lt: recipe.footerText.lt,
				no: recipe.footerText.no,
			},
			tags: tags,
			difficultyLevel: difficultyLevel,
		}

		const jsonString = JSON.stringify(json, null, '\t')
		sendRecipeToBackend(jsonString)
	}


	const sendRecipeToBackend = (recipeJson) => {
		const token = getToken()
		const apiUrl = getApiServerUrl()
		axios.post(`${apiUrl}/admin/recipe`, recipeJson, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		})
			.then(response => {
				// response from the backend

				console.log('Success:', response.data)
				// window.location.reload() // Refresh the page
				if (onSave) {
					onSave()
				}
			})
			.catch(error => {
				// deal with error
				console.error('Error:', error)
			})
	}

	return (
		<div className="recipe-input-form">
			<h3>Recipe Input</h3>

			<TextInput label="ID" value={recipe.id} disabled={true} onChange={(value) => updateRecipe('id', value)} />

			<MultiLanguageInput label="Title" values={recipe.title} onChange={updateRecipeTitle} />

			<TextInput label="Image" value={recipe.images} onChange={(value) => updateRecipe('images', value)} />

			<RecipeAuthor recipe={recipe} onChange={updateRecipe} />

			<SingleSelect 
				label="Difficulty level"
				options={['easy', 'medium', 'hard']}
				value={difficultyLevel}
				setValue={setDifficultyLevel}
			/>

			<SingleSelect 
				label="Types of meals"
				options={['breakfast', 'main_course', 'soup', 'dessert']}
				value={recipe.typesOfMeals}
				setValue={(value) => updateRecipe('typesOfMeals', value)}
			/>

			<TextInput label="Servings" value={recipe.servings} onChange={(value) => updateRecipe('servings', value)} />
			<TextInput label="Time amount" value={recipe.timeAmount} onChange={(value) => updateRecipe('timeAmount', value)} />
			<TextInput label="Time unit" value={recipe.timeUnit} onChange={(value) => updateRecipe('timeUnit', value)} />

			<RecipeTags setTags={setTags} tags={tags} />

			<IngredientsInputForm ingredients={ingredients} ingredientList={ingredientList} addOneIngredient={addOneIngredient} updateIngredient={updateIngredient} removeOneIngredient={removeOneIngredient} />
			
			<MultiLanguageText label="Intro" values={recipe.introText} onChange={updateRecipeIntro} />

			<CookingProcessInputForm steps={
				steps} addOneCookingProcessStep={addOneCookingProcessStep} updateCookingProcessStep={updateCookingProcessStep} removeOneCookingProcessStep={removeOneCookingProcessStep} />

			<MultiLanguageText label="Footer" values={recipe.footerText} onChange={updateRecipeFooter} />

			<div className="publish-status">
				publish
				<Switch
					checked={recipe.published === true}
					onChange={checked => updateRecipe('published', checked ? true : false)}
					onColor="#0D6EFD"
					offColor="#DADADA"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
				/>
			</div>
			<div className="save-recipe">
				<Button variant="primary" onClick={() => showRecipeJson(recipe, ingredients, steps)}>Save Recipe</Button>

				<Link to="/recipe-list">
					<Button variant="primary" style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#DC3545' }}>Cancel</Button>
				</Link>
			</div>
		</div>
	)
}

