import IngredientForm from './IngredientForm'
import { useEffect, useState } from 'react'
import { getApiServerUrl } from '../Config'
import axios from 'axios'
import { getToken } from '../util/api'
import React from 'react'
import { useLocation } from 'react-router-dom'

const EditIngredient = () => {
  const location = useLocation()
  const apiUrl = getApiServerUrl()
  const token = getToken()

  const ingredientId = location.pathname.split('/').pop()
  const [ingredientData, setIngredientData] = useState(null)


  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/ingredient/${ingredientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      console.log(response.data)
      setIngredientData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [ingredientId, token])

  if (!token) {
    window.location = '/login'
  }



  return (
    <div style={{ width: '100%' }}>
      <IngredientForm ingredientData={ingredientData} />
    </div>
  )
}

export default EditIngredient