import RecipeInputForm from './RecipeInputForm'
import { useEffect, useState } from 'react'
import { getApiServerUrl } from '../Config'
import axios from 'axios'
import { getToken } from '../util/api'
import React from 'react'
import { useLocation } from 'react-router-dom'
import RecipeViewForm from './RecipeViewForm'

import './EditRecipe.css'

const EditRecipe = () => {
  const location = useLocation()
  const apiUrl = getApiServerUrl()
  const token = getToken()

  const recipeId = location.pathname.split('/').pop()
  const [recipeData, setRecipeData] = useState(null)

  const [viewKey, setViewKey] = useState(new Date().getTime())

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/recipe/${recipeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      console.log(response.data)
      setRecipeData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [recipeId, token])

  if (!token) {
    window.location = '/login'
  }



  return (
    <div className='EditRecipe' >
      <div className='recipe-input'>
        <RecipeInputForm recipeData={recipeData} onSave={() => setViewKey(new Date().getTime())} />
      </div>
      <div className='recipe-view'>
        <RecipeViewForm id={recipeData?.id} key={viewKey} />
      </div>
    </div>
  )
}

export default EditRecipe;

