import { useNavigate } from 'react-router-dom'
import qs from 'qs'
import { setToken } from './util/api';

export default function LoginResult() {
    const navigate = useNavigate()

    const location = qs.parse(window.location.hash)

    const token = location.access_token
    const expires = location.expires_in * 1000 + Date.now()
    setToken(token, expires)
    window.location = '/'
    return <>
    <button className="button-language" style={{width: '200px'}} onClick={() => navigate('/')}>Open</button>
    </>
}