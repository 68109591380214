import Form from 'react-bootstrap/Form'

import './SingleSelect.css'

const SingleSelect = ({ label, options, value, setValue, className = '' }) => {
    return <div className={`SingleSelect ${className}`}>
            <label>{label}: </label>
            <div style={{ width: '100%' }} className='SingleSelectOptions'>
            {options.map((option) => (
                <Form.Check 
                    type="radio"
                    key={`${label}-${option}`}
                    id={`${label}-${option}`}
                    name={label} 
                    value={option} 
                    aria-label={option} 
                    label={option} 
                    checked={value === option || value[0] === option} 
                    onChange={(e) => setValue(e.target.value)} 
                />
            ))}
            </div>
        </div>
}

export default SingleSelect