import Select from 'react-select'
import Form from 'react-bootstrap/Form'

import './RecipeTags.css'

const options = [
    {
      label: "Dietary",
      options: [
        { label: "vegan", value: "vegan" },
        { label: "vegetarian", value: "vegetarian" },
        { label: "gluten-free", value: "gluten-free" },
        { label: "keto", value: "keto" },
        { label: "dairy-free", value: "dairy-free" },
        { label: "nut-free", value: "nut-free" },
        { label: "meat-free", value: "meat-free" },
        { label: "chicken", value: "chicken" },
        { label: "beef", value: "beef" },
        { label: "fish", value: "fish" },
        { label: "seafood", value: "seafood" },
        { label: "cheese", value: "cheese" },
        { label: "eggs", value: "eggs" },
        { label: "vegetables", value: "vegetables" },
        { label: "meat", value: "meat" },
      ]
    },
    {
        label: "Occasion",
        options: [
          { label: "birthday", value: "birthday" },
          { label: "christmas", value: "christmas" },
          { label: "valentines-day", value: "valentines-day" },
          { label: "easter", value: "easter" },
        ]
    },
    {
        label: "Flavor",
        options: [
          { label: "spicy", value: "spicy" },
          { label: "sweet", value: "sweet" },
        ]
    },
    {
        label: "Other",
        options: [
            { label: "side dishes", value: "side dishes" },
            { label: "bread", value: "bread" },
            { label: "yeast sweet pastries", value: "yeast sweet pastries" },
            { label: "biscuits", value: "biscuits" },
            { label: "cakes", value: "cakes" },
            { label: "pancakes", value: "pancakes" },
            { label: "porridge", value: "porridge" },
            { label: "sandwiches", value: "sandwiches" },
            { label: "minced meat", value: "minced meat" },
            { label: "pasta", value: "pasta" },
            { label: "pizza", value: "pizza" },
            { label: "stews", value: "stews" },
            { label: "one pot", value: "one pot" },
            { label: "appetizers", value: "appetizers" },
        ]
    },
]

const RecipeTags = ({tags, setTags}) => {
	const tagOptions = [].concat(options[0].options, options[1].options, options[2].options, options[3].options)

	return <div className="RecipeTags">
		<label>Tags: </label>
    <div style={{width: '100%'}} className='RecipeTagsOptions'>
    { tagOptions.map(tag => {
      return ( 
        <Form.Check
          type="switch"
          id={`recipe-tags-${tag.value}`}
          key={`recipe-tags-${tag.value}`}
          label={tag.label}
          value={tag.value}
          checked={tags.includes(tag.value)}
          onChange={(tag) => {
            if (tags.includes(tag.target.value)) {
              const newTags = tags.filter(item => item !== tag.target.value)
              setTags(newTags)
            } else {
              const newTags = [...tags, tag.target.value]
              setTags(newTags)
            }
          }}
        />
      )
    })}
		</div>
	</div>
}

export default RecipeTags
