import Form from 'react-bootstrap/Form'

import './TextInput.css'

const TextInput = ( {label, value, onChange, disabled = false} ) => {
    return <div className='TextInput'>
        <label>{label}</label>
        <Form.Control disabled={disabled} type="text" placeholder="" value={value} onChange={(e) => onChange(e.target.value) } />
    </div>
}

export default TextInput